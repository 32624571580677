<template>
  <div id="layout-landingpage">
    <AppCookieConsent v-if="!route?.query?._storyblok" />

    <Transition name="fade-in">
      <ShopCartPopup v-if="isCartStoreOpen" />
    </Transition>

    <slot />

    <NuxtLazyHydrate when-visible>
      <AppFooter :is-reduced="true" />
    </NuxtLazyHydrate>
  </div>
</template>

<script setup>
/*
  Initialize variables
*/
const route = useRoute();
const cartStore = useCartStore();

const cartPopupStore = useCartPopupStore();
const isCartStoreOpen = computed(() => cartPopupStore.getRenderState);

onMounted(() => {
    cartStore.initStrapiClient();
    cartStore.initializeCart();
});
</script>
